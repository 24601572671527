
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Reports } from '@/modules/reports'
import { IVisitsReportFilter, IVisitsReportSearch } from '../types';
import { ITableHeader, IPagination } from '../../system/types';

@Component
export default class DoctorsReport extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  reports = $app.module(Reports);

  loading = false;

  filter: IVisitsReportFilter = $app.clone(this.reports.$store.DoctorSearch.filter);

  get headers(): Array<ITableHeader> {
    return [
      {
        text: $app.i18n('dplus.reports.DoctorName'),
        value: 'DoctorName',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.reports.VisitCount'),
        value: 'VisitCount',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.reports.VisitTotalAmount'),
        value: 'VisitTotalAmount',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.reports.VisitOriginalAmount'),
        value: 'VisitOriginalAmount',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.reports.PayType'),
        value: 'PayType',
        align: 'center',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.reports.ResearchPrice'),
        value: 'ResearchPrice',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.reports.ResearchPriceWait'),
        value: 'ResearchPriceWait',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.reports.VistDebt'),
        value: 'VistDebt',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.reports.AttractCount'),
        value: 'AttractCount',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.reports.SalaryValue'),
        value: 'SalaryValue',
        align: 'right',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.reports.FeeHistoryValue'),
        value: 'FeeHistoryValue',
        align: 'right',
        width: '10%'
      }
    ];
  }

  get sumVisitCount() {
    let amt = 0
    this.reports.$store.DoctorSearch.items.forEach(i => { amt += 1 })
    return amt;
  }

  get sumVisitTotalAmount() {
    let amt = 0
    this.reports.$store.DoctorSearch.items.forEach(i => { amt += i.visitTotalAmount || 0 })
    return amt;
  }

  get sumVisitOriginalAmount() {
    let amt = 0
    this.reports.$store.DoctorSearch.items.forEach(i => { amt += i.visitOriginalAmount || 0 })
    return amt;
  }

  get sumResearchPrice() {
    let amt = 0
    this.reports.$store.DoctorSearch.items.forEach(i => { amt += i.researchPrice || 0 })
    return amt;
  }

  get sumResearchPriceWait() {
    let amt = 0
    this.reports.$store.DoctorSearch.items.forEach(i => { amt += i.researchPriceWait || 0 })
    return amt;
  }

  get sumVistDebt() {
    let amt = 0
    this.reports.$store.DoctorSearch.items.forEach(i => { amt += i.vistDebt || 0 })
    return amt;
  }

  get sumAttractCount() {
    let amt = 0
    this.reports.$store.DoctorSearch.items.forEach(i => { amt += i.attractCount || 0 })
    return amt;
  }

  get sumSalaryValue() {
    let amt = 0
    this.reports.$store.DoctorSearch.items.forEach(i => { amt += i.salaryValue || 0 })
    return amt;
  }

  get sumFeeHistoryValue() {
    let amt = 0
    this.reports.$store.DoctorSearch.items.forEach(i => { amt += i.feeHistoryValue || 0 })
    return amt;
  }

  get search(): IVisitsReportSearch {
    return this.reports.$store.DoctorSearch;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      const filter: IPagination = $app.clone(pagination)
      if (filter.itemsPerPage < 0) {
        filter.itemsPerPage = 9999999;
      }
      await this.reports.$store.doctorReport(filter);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.reports.$store.DoctorSearch, this.filter);
    this.reports.$store.setDoctorSearch({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
